<template>
  <BModal
    id="modal-reservation-modify-seat-update"
    header-bg-variant="light-info"
    header-class="p-75 flex-column"
    body-class="px-25"
    footer-class="p-50"
    no-close-on-backdrop
    no-enforce-focus
    centered
    size="xl"
    @hide="handleHideModal"
    @show="handleShowModal"
  >
    <template #modal-header="{close}">
      <BRow class="justify-content-between align-items-center w-100 mx-0">
        <h4 class="text-airline fw-700 m-50">
          {{ $t('reservation.selectSeat') }}
        </h4>
        <BButton
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="flat-secondary"
          @click="close()"
        >
          <feather-icon icon="XIcon" />
        </BButton>
      </BRow>
    </template>

    <template #modal-footer="{close}">
      <BButton
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-danger"
        pill
        @click="close()"
      >
        {{ $t('customer.cancel') }}
      </BButton>

      <BButton
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25"
        pill
        :disabled="(!dataToSelectSeat.some(item => item.hasAddedSeat))"
        @click="openSelectPayOptionModalHandle"
      >
        <span class="align-middle px-2">{{ $t('reservation.continue') }}</span>
      </BButton>
    </template>

    <BRow
      v-if="segmentData && segmentData.segment"
      class="justify-content-center fw-700 pt-1"
    >
      {{ $t('flight.flightSegment') }}: {{ segmentData.segment.departure.iataCode }} - {{ segmentData.segment.arrival.iataCode }}
      <small class="text-info font-weight-bolder ml-50">
        ({{ convertISODateTime(segmentData.segment.departure.at, segmentData.segment.departure.timeZone).date }})
      </small>
    </BRow>

    <!-- ANCHOR - AIR-CRAFT -->
    <BRow
      v-if="seatsData"
      class="justify-content-around mb-2 mr-1 pb-1"
    >
      <BCol
        cols="12"
        class="text-center font-weight-bolder font-medium-3 mb-2"
      >
        {{
          getAirCraftByIata(seatsData.seatsMap.aircraft) ||
            seatsData.seatsMap.aircraft
        }}
      </BCol>
      <BCol class="text-center">
        <img
          class="text-info"
          src="@icons/seat-available.svg"
          style="width: 30px;height:30px"
        >
        <p class="mb-0 mt-50">
          {{ $t('reservation.Available') }}
        </p>
      </BCol>

      <BCol class="text-center">
        <img
          class="text-secondary"
          src="@icons/seat-unavailable.svg"
          style="width: 30px;height:30px"
        >
        <p class="mb-0 mt-50">
          {{ $t('reservation.Unavailable') }}
        </p>
      </BCol>
    </BRow>

    <BRow
      no-gutters
      class="mt-1"
    >
      <!-- ANCHOR - TABLE GHẾ ĐÃ CHỌN -->
      <ColSelectSeat
        :data-to-select-seat="dataToSelectSeat"
        :get-is-empty-seat-map-data="getIsEmptySeatMapData"
        :booking-source="bookingSource"
        :seats-data="seatsData"
        :segment-data="segmentData"
        @handleDeleteSeat="handleDeleteSeatInTable"
      />

      <!-- ANCHOR SEAT-MAP -->
      <BCol>
        <BAlert
          :show="isEmptySeat"
          variant="warning"
          class="p-1 mx-1"
        >
          Không tìm thấy dữ liệu sơ đồ ghế cho hành trình này.
        </BAlert>

        <template v-if="!isEmpty(seatsOption)">
          <BRow
            v-for="(seatsOptionItem, seatsOptionIndex) in seatsOption"
            :key="seatsOptionIndex"
            :class="`flex-nowrap mx-0 px-2 px-md-0 py-1 overflow-auto ${seatsOption.length > 1 ? 'mb-2' : ''}`"
            style="justify-content: safe center;"
            no-gutters
          >
            <div
              v-for="(groupColumnSeat, groupColumnSeatIndex) of seatsOptionItem.dataSeats"
              :key="groupColumnSeatIndex"
              class="d-flex flex-column align-items-center"
              :style="(['xs','sm','md'].includes(appBreakPoint) && (groupColumnSeat.length === 1) ) ? `width:18px;` : ''"
            >
              <!-- TODO - Get column -->
              {{ groupColumnSeat && groupColumnSeat.length >= 1 ? `${groupColumnSeat[0].col !== 'AISLE_LOCATION' ? groupColumnSeat[0].col : '' }` : '' }}
              <div
                v-for="(seat, seatsRowIndex) in groupColumnSeat"
                :key="seatsRowIndex"
                class="text-center"
                :style="['xs','sm','md'].includes(appBreakPoint) ? `width:30px; height:35px` : `height:45px; width:45px;`"
              >
                <ButtonSeat
                  :seat="seat"
                  :seat-already-chosen-arr="seatAlreadyChosenArr"
                  :data-to-select-seat="dataToSelectSeat"
                  :source="bookingSource"
                  @handleClickSeat="handleSelectSeat"
                />
              </div>
            </div>
          </BRow>
        </template>

        <!-- ANCHOR - loading seatmap -->
        <BRow
          v-else-if="getIsEmptySeatMapData === 'loading'"
          class="justify-content-center align-items-center flex-column height-150 w-100 mx-0"
        >
          <BSpinner variant="info" />
          <i class="text-info">Đang lấy dữ liệu sơ đồ chỗ ngồi ...</i>
        </BRow>
        <!-- ANCHOR - loading seatmap -->
        <BRow
          v-else-if="getIsEmptySeatMapData === 'empty'"
          class="mx-0 d-flex-center"
        >
          <BAlert
            variant="warning"
            show
            class="py-1 px-2 mt-2"
          >
            <p> Không tìm thấy dữ liệu sơ đồ ghế cho hành trình này.</p>
            <p>Vui lòng kiểm tra hạng vé, tải lại trang hoặc liên hệ với Booker!</p>
          </BAlert>
        </BRow>
      </BCol>
    </BRow>

    <ModalAncillaryPayOptions
      v-if="bookingData"
      :reservations-data="bookingData"
      :is-pay-now.sync="isPayNow"
      :has-paid-trip="hasPaidTrip"
      :place="bookingSource"
      @submit="handleConfirmModifySeat"
    />
  </BModal>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'

import { resolveCabinDetails } from '@/constants/selectOptions'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'
import store from '@/store'

import { validatorPaymentPassword } from '@core/utils/validations/validators'
import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'
import ModalAncillaryPayOptions from '@reservation/reservation-modify/components/detail/components/ModalAncillaryPayOptions.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BSpinner,
    BAlert,

    ModalAncillaryPayOptions,
    ButtonSeat: () => import('@/components/seat-map/button-seat.vue'),
    ColSelectSeat: () => import('./table-select-seat.vue'),
  },
  directives: {
    Ripple,
  },
  props: {
    segmentData: {
      type: Object,
      default: () => {},
    },
    seatsMapData: {
      type: Array,
      default: () => [],
    },
    isOpenModalSeat: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const {
      modifyAncillary,
      modifyReserveSeat,
      getIsEmptySeatMapData,
      getBookingData: bookingData,
    } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
      getAirlineBySource,
      getAirCraftByIata,
    } = useAncillaryServicesHandle()

    const hasPaidTrip = ref(false) // trạng thái thanh toán của trip
    const isPayNow = ref(false)

    const seatsMapFullCabinsData = ref(null)
    const seatAlreadyChosenArr = ref([])
    const dataToSelectSeat = ref([])

    function resolvePriceSeat(seatData) {
      let total = 0
      if (!isEmpty(seatData.seatSelected.fares)) {
        const findFareByPaxType = seatData.seatSelected.fares.find(fare => fare.paxType === seatData.passengerData.paxType)
        if (findFareByPaxType) {
          total = findFareByPaxType.total
        } else {
          total = seatData.seatSelected.fares[0].total
        }
      }
      return total
    }

    const totalPricesAddSeat = computed(() => {
      let result = 0
      const passengerEmptySeatsSelected = dataToSelectSeat.value.filter(item => item.hasAddedSeat)
      if (!isEmpty(passengerEmptySeatsSelected)) {
        result = passengerEmptySeatsSelected.reduce((total, item) => total + resolvePriceSeat(item), 0)
      }
      return result
    })

    // get Price seat by ancillaryServices
    function getTotalPriceSeatByAncillaryServices(booking, paxSeatData) {
      let total = 0
      const listSeatByBooking = booking.ancillaryServices.filter(item => item.serviceType === 'SEAT')

      if (!isEmpty(listSeatByBooking)) {
        const getSeat = listSeatByBooking.find(item => item.paxId === paxSeatData.paxId
        && String(item.segmentIds[0]) === String(paxSeatData.segmentId)
        && item.pdcSeat === paxSeatData.seatNumbers)

        total = getSeat ? getSeat.totalPrice : 0
      }

      return total
    }

    // NOTE - Table Seat Passengers, lấy data từ ancillaryServices
    function filterSeatBySegment(booking, segmentData) {
      const listPassenger = booking.paxLists.filter(p => p.paxType !== 'INFANT')
      dataToSelectSeat.value = []

      listPassenger.forEach(passenger => {
        let seatSelected = null
        let alreadySeat = false

        try {
          if (!isEmpty(passenger.seats)) {
            const hasSeatInSegment = passenger.seats.find(s => s.paxId === passenger.paxId && String(s.segmentId) === String(segmentData.segment.devAddonsSegmentIndex))
            if (hasSeatInSegment) {
              alreadySeat = true
              seatSelected = {
                row: hasSeatInSegment.seatNumbers.slice(0, -1),
                col: hasSeatInSegment.seatNumbers.slice(-1),
                fares: [
                  {
                    total: getTotalPriceSeatByAncillaryServices(booking, hasSeatInSegment),
                  },
                ],
              }
            }
          }
        } catch (error) {
          console.error('error in filterSeatBySegment::', { error })
        }

        dataToSelectSeat.value.push({
          passengerData: passenger,
          hasAddedSeat: false,
          seatSelected,
          alreadySeat,
        })
      })
    }

    watch(
      [
        () => props.segmentData,
        () => props.seatsMapData,
        () => props.isOpenModalSeat,
      ],
      ([newSegmentData, newSeatsMapData, openModalSeat]) => {
        try {
          if (
            !isEmpty(newSegmentData)
           && !isEmpty(newSeatsMapData)
           && !isEmpty(bookingData.value.itineraries)
           && openModalSeat
          ) {
            // get data table
            seatsMapFullCabinsData.value = null
            filterSeatBySegment(bookingData.value, newSegmentData)

            // get data seat already chosen
            bookingData.value.paxLists.forEach(p => {
              // if (p.paxType !== 'INFANT' && ['1S', '1S_CTRL'].includes(bookingSource.value)) {
              //   if (isEmpty(p.eticket)) {
              //     hasPaidTrip.value = false
              //     return
              //   // eslint-disable-next-line no-else-return
              //   } else {
              //     const eticketInSegment = p.eticket.find(e => e.segments.some(s => s.departure === newSegmentData.segment.departure.iataCode && new Date(s.departureDate).toISOString() === newSegmentData.segment.departure.at))
              //     if (!eticketInSegment) {
              //       hasPaidTrip.value = false
              //       return
              //     }
              // hasPaidTrip.value = !!eticketInSegment
              // console.log('check: !!eticketInSegment', hasPaidTrip.value)
              //   }
              // }

              if (!isEmpty(p.seats)) {
                p.seats.forEach(seat => {
                  if (seat.segmentId === String(newSegmentData.segment.devAddonsSegmentIndex)) {
                    seatAlreadyChosenArr.value.push({
                      ...seat,
                      row: seat.seatNumbers.slice(0, -1),
                      col: seat.seatNumbers.slice(-1),
                    })
                  }
                })
              }
            })

            const airCraftByAirline = newSeatsMapData.find(
              item => item.airline === props.segmentData.segment.airline,
            )

            // FIXME FIRST OF ALL ERORRRRRRRRR
            const getSeatsMapBySegment = {
              airline: airCraftByAirline.airline,
              source: airCraftByAirline.source,
              seatsMap: airCraftByAirline.seatsMap.filter(
                item => (item.flights === props.segmentData.segment.flights)
                && (
                  ['VN1A', 'VN1A_MT'].includes(bookingSource.value)
                    ? (item.itineraryId === newSegmentData.segment.devItineraryIndex)
                    : true
                ),
              ),
            }

            seatsMapFullCabinsData.value = getSeatsMapBySegment
          }
        } catch (error) {
          console.error(error)
        }
      },
      { deep: true, immediate: true },
    )

    // ANCHOR - SP
    function getSeatsByCabin(data, cabinClass) {
      let result
      try {
        if (data.airline === 'QH') {
          result = {
            ...data,
            seatsMap: data.seatsMap.reduce((acc, aircraft) => {
              const { cabinDetails, ...restAirCraft } = aircraft
              // const cabinData = cabinDetails.find(
              //   detail => detail.cabin === cabinClass,
              // )
              // const cabinData = cabinDetails.find(
              //   detail => detail.cabin === cabinClass,
              // )
              return {
                ...restAirCraft,
                cabinData: cabinDetails,
              }
            }, {}),
          }
        } else {
          result = {
            ...data,
            seatsMap: data.seatsMap.reduce((acc, aircraft) => {
              const { cabinDetails, ...restAirCraft } = aircraft
              const cabinData = !['VJ', 'VU'].includes(bookingSource.value) ? resolveCabinDetails(cabinDetails, props.segmentData.segment) : [resolveCabinDetails(cabinDetails, props.segmentData.segment)]
              // FIXME - restAirCraft, cabinData
              return {
                ...restAirCraft,
                cabinData,
              }
            }, {}),
          }
        }
      } catch (error) {
        console.error('error in SeatsByCabin:', error)
      }
      return result
    }

    // ANCHOR - SP
    function findEmptyItemArray(newArr, { aircraft }) {
      const arrSeat = newArr
      for (let i = 0; i < arrSeat.length; i += 1) {
        const row = Number(arrSeat[i].row) + 1
        if (arrSeat[i + 1] && (row < arrSeat[i + 1].row)) {
          if (!['VJ'].includes(bookingSource.value) || (['VJ'].includes(bookingSource.value) && (row !== 13) && ['321', '330'].includes(aircraft)) || (['VJ'].includes(bookingSource.value) && row === 13 && ['320'].includes(aircraft))) {
            arrSeat.splice(i + 1, 0, {
              isAvailable: false,
              row,
              col: arrSeat[i].col,
              location: 'NoSeatAtThisLocation',
              fares: [],
            })
          }
        }
      }

      if (!['VJ'].includes(bookingSource.value)) return arrSeat

      if (['320', '321', '330'].includes(aircraft)) {
        for (let i = 0; i < arrSeat.length; i += 1) {
          const row = Number(arrSeat[i].row) + 1
          const addRow = ['320'].includes(aircraft) ? [12].includes(row) : ['321'].includes(aircraft) ? [11, 27].includes(row) : ['330'].includes(aircraft) ? [15, 34].includes(row) : false

          if (addRow) {
            arrSeat.splice(i + 1, 0, {
              isAvailable: false,
              row,
              col: arrSeat[i].col,
              location: 'NoSeatAtThisLocation',
              fares: [],
            })
          }
        }
      }

      return arrSeat
    }

    const seatsData = ref(null)
    watch(
      () => seatsMapFullCabinsData.value,
      data => {
        if (!isEmpty(data)) {
          seatsData.value = getSeatsByCabin(data, props.segmentData.segment.cabinClass)
        }
      },
      { deep: true, immediate: true },
    )

    // ANCHOR - SP
    function groupSeatsByColumns(cabinData, { aircraft }) {
      try {
        // const arrSortSeat = cabinData.seatOptions.filter(s => Number(s.row)).sort((a, b) => (a?.row || 1) - (b?.row || 0))
        // const resultNumberOfFirstSeat = arrSortSeat?.[0]?.row || 0

        const columnCount = cabinData.cabinConfig
          .split('-')
          .reduce((sum, val) => sum + Number(val), 0)

        const columnLetters = cabinData.cabinConfigInternal.split('-')
        const columns = Array.from({ length: columnCount }, () => [])
        const seatOptionsArr = cabinData.seatOptions.filter(
          seat => seat.col && Number(seat.col) !== 0,
        )

        seatOptionsArr.forEach(option => {
          const letterIndex = columnLetters.indexOf(option.col)
          const colIndex = letterIndex >= 0
            ? letterIndex
            : Number(option.col) - 1 + columnLetters.length

          if (columns[colIndex]) {
            // const row0 = Number(seatOptionsArr[0]?.row ?? 0)
            // if ((row0 !== 0) && (Number(resultNumberOfFirstSeat) !== 0) && (row0 > Number(resultNumberOfFirstSeat))) {
            //   const hiddenItem = {
            //     row: 'AISLE_LOCATION',
            //     col: 'AISLE_LOCATION',
            //     location: 'NoSeatAtThisLocation',
            //   }
            //   for (let index = 0; index < Number(resultNumberOfFirstSeat); index += 1) {
            //     columns[colIndex].push(hiddenItem)
            //   }
            // }

            columns[colIndex].push(option)
          }
        })

        for (let i = 0; i < columns.length; i += 1) {
          if (columns[i].length) {
            columns[i] = findEmptyItemArray(columns[i], { aircraft })
          }
        }
        return columns
      } catch (error) {
        console.error({ error })
        return []
      }
    }

    // ANCHOR - SP
    function addSeatToLayout(config, data) {
      const result = []
      let index = 0
      for (let i = 0; i < config.length; i += 1) {
        const row = []
        if (config[i] !== '0') {
          for (let j = 0; j < Number(config[i]); j += 1) {
            row.push(data[index])
            index += 1
          }
          result.push(row)
        }
      }
      return result
    }

    // function sortByRowAscending(data) {
    //   const flattenedData = data.reduce((acc, curr) => acc.concat(curr), [])
    //   const filteredData = flattenedData.filter(row => Number(row.row))
    //   if (filteredData.length === 0) {
    //     return 0
    //   }
    //   const sortedData = filteredData.sort((a, b) => (Number(a.row) || 1) - (Number(b.row) || 0))
    //   if (sortedData.length > 0) return Number(sortedData[0].row)
    //   return 0
    // }

    // ANCHOR - SP
    function convertSeats(cabinConfig, data) {
      // numberOfFirstSeat: nếu ghế ở hàng hiện tại bắt đầu có số ghế
      // nhỏ hơn ghế đầu tiên trên toàn hàng thì sẽ thêm các ghế trống trước ghế đó
      // const numberOfFirstSeat = sortByRowAscending(data)
      const seats = addSeatToLayout(cabinConfig, data)

      const result = []
      let emptyCount = 0

      for (let i = 0; i < seats.length; i += 1) {
        const row = seats[i]
        if (row.length === 0) {
          emptyCount += 1
          if (emptyCount === 1) {
            result.push([
              [
                {
                  row: 'AISLE_LOCATION',
                  col: 'AISLE_LOCATION',
                  location: 'NoSeatAtThisLocation',
                },
              ],
            ])
          }
        } else {
          emptyCount = 0
          result.push(row)
        }
      }
      return [].concat(...result)
    }

    const isEmptySeat = ref(false)
    const seatsOption = ref([])
    watch(
      () => seatsData.value,
      val => {
        if (!isEmpty(val)) {
          // ANCHOR Ghép mảng seat by duclt
          try {
            seatsData.value.seatsMap.cabinData.forEach(cabinItem => {
              const cabinConfig = cabinItem.cabinConfig
              const data = groupSeatsByColumns(cabinItem, { aircraft: seatsData.value?.seatsMap?.aircraft || '' })
              seatsOption.value.push({
                cabinConfig,
                dataSeats: convertSeats(cabinConfig, data),
              })
            })
          } catch (error) {
            isEmptySeat.value = true
            console.error('error seatsData.value::', error)
          }

          // NOTE cái này seat cũ của LINHVN
          // const cabinConfig = seatsData.value.seatsMap.cabinData[0].cabinConfig
          // const data = groupSeatsByColumns(val)
          // seatsOption.value = {
          //   cabinConfig,
          //   dataSeats: convertSeats(cabinConfig, data),
          // }
        }
      },
      { immediate: true },
    )

    function handleDeleteSeatInTable(data) {
      const pIndex = dataToSelectSeat.value.findIndex(
        item => item.passengerData.paxId === data.passengerData.paxId,
      )
      dataToSelectSeat.value[pIndex].seatSelected = null
      dataToSelectSeat.value[pIndex].hasAddedSeat = false
    }

    function handleSelectSeat(seatItem) {
      const listPassengerEmptySeats = dataToSelectSeat.value.filter(item => !item.alreadySeat)
      let data = null

      switch (true) {
        case listPassengerEmptySeats.every(p => p.seatSelected !== null): {
          const existingSeat = listPassengerEmptySeats.find(p => String(p.seatSelected.row) === String(seatItem.row) && String(p.seatSelected.col) === String(seatItem.col))
          if (existingSeat) {
            const passengerIndex = dataToSelectSeat.value.findIndex(p => p.passengerData.paxId === existingSeat.passengerData.paxId)
            data = {
              index: passengerIndex,
              seat: null,
              hasAddedSeat: false,
            }
          }
          break
        }
        case listPassengerEmptySeats.every(p => p.seatSelected === null): {
          const passengerIndex = dataToSelectSeat.value.findIndex(p => p.passengerData.paxId === listPassengerEmptySeats[0].passengerData.paxId)
          data = {
            index: passengerIndex,
            seat: seatItem,
            hasAddedSeat: true,
          }
          break
        }
        default: {
          const existingSeat = listPassengerEmptySeats.find(p => p.seatSelected && String(p.seatSelected.row) === String(seatItem.row) && String(p.seatSelected.col) === String(seatItem.col))
          if (existingSeat) {
            const passengerIndex = dataToSelectSeat.value.findIndex(p => p.passengerData.paxId === existingSeat.passengerData.paxId)
            data = {
              index: passengerIndex,
              seat: null,
              hasAddedSeat: false,
            }
          } else {
            const passengerWithoutSeat = listPassengerEmptySeats.find(p => p.seatSelected === null)
            const passengerIndex = dataToSelectSeat.value.findIndex(p => p.passengerData.paxId === passengerWithoutSeat.passengerData.paxId)
            data = {
              index: passengerIndex,
              seat: seatItem,
              hasAddedSeat: true,
            }
          }
          break
        }
      }

      if (data) {
        dataToSelectSeat.value[data.index].seatSelected = data.seat
        dataToSelectSeat.value[data.index].hasAddedSeat = data.hasAddedSeat
      }
    }

    function wrapPayload() {
      const seatData = []

      const dataNewAddedSeat = dataToSelectSeat.value.filter(item => item.hasAddedSeat)

      dataNewAddedSeat.forEach(item => {
        let totalPrice = 0
        let seatValue = null

        if (!isEmpty(item.seatSelected.fares)) {
          // if (item.seatSelected.fares[0].paxType === 'ALL') {
          //   totalPrice = item.seatSelected.fares[0].total
          //   seatValue = item.seatSelected.fares[0].seatValue
          // }

          const findFareByPaxType = item.seatSelected.fares.find(fare => fare.paxType === item.passengerData.paxType)
          if (findFareByPaxType) {
            totalPrice = findFareByPaxType.total
            seatValue = item.seatSelected?.seatValue || null
          } else {
            totalPrice = item.seatSelected.fares[0].total
            seatValue = item.seatSelected.fares[0].seatValue ? item.seatSelected.fares[0].seatValue : null
          }
        }

        seatData.push({
          paxId: item.passengerData.paxId,
          seatNumber: `${item.seatSelected.row}${item.seatSelected.col}`,
          // segmentId: props.segmentData.segment.devAddonsSegmentIndex,
          segmentId: props.segmentData.segment.segmentId,
          boardPoint: props.segmentData.segment.departure.iataCode,
          offPoint: props.segmentData.segment.arrival.iataCode,
          seatValue,
          // ...(seatsMapFullCabinsData.value.airline === 'VJ' && { seatValue: seatValue })
          itineraryId: props.segmentData.segment.itineraryId || null,
          total: totalPrice,
        })
      })

      return seatData
    }

    async function openSelectPayOptionModalHandle() {
      this.$bvModal.show('modal-modify-ancillary-pay-options')
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    async function handleConfirmModifySeat() {
      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPayNow.value) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          allowOutsideClick: true,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }
      const paymentData = {
        isPayNow: isPayNow.value,
        paymentPassword: paymentPassword?.value || '',
      }

      if (['TR'].includes(bookingSource.value)) {
        this.$bvModal.show('modal-api-loading')

        const dataNewAddedSeat = dataToSelectSeat.value.filter(item => item.hasAddedSeat)
        const segment = props.segmentData.segment

        const dataTR = dataNewAddedSeat.map((item, index) => ({
          ancillaryCode: `${item.seatSelected.row}${item.seatSelected.col}`,
          ancillaryType: 'SEAT',
          ancillaryValue: item.seatSelected.seatValue,
          paxInfo: {
            type: item.passengerData.paxType,
            paxId: item.passengerData.paxId,
            firstName: item.passengerData.firstName,
            lastName: item.passengerData.lastName,
          },
          itineraries: [{
            airline: segment.airline,
            departure: segment.departure.iataCode,
            arrival: segment.arrival.iataCode,
            departureDate: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
            arrivalDate: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
            flightNumber: segment.flightNumber,
            segmentId: segment.segmentId,
            fareBasisCode: segment?.fareBasisCode || '',
            bookingClass: segment.bookingClass,
            groupClass: segment.groupClass,
            bookingStatus: segment.bookingStatus,
            itineraryId: segment?.itineraryId || '', // check
          }],
          quantity: 1,
          total: 1,
          operation: 'ADD',
          id: (index + 1).toString(),
        }))

        modifyAncillary(bookingData.value, dataTR, paymentData)
          .then((() => {
            const modalShow = Array.from(window.document.querySelectorAll('.modal.show')).map(el => el.id)
            modalShow.forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
          }))
          .finally(() => this.$bvModal.hide('modal-api-loading'))
      } else {
        this.$bvModal.show('modal-api-loading')
        const data = await wrapPayload()
        modifyReserveSeat(bookingData.value, data, paymentData)
          .then(() => {
            const modalShow = Array.from(window.document.querySelectorAll('.modal.show'))
              .map(el => el.id).filter(id => ['modal-reservation-modify-seat-update', 'modal-modify-ancillary-pay-options'].includes(id))

            modalShow.forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
          })
          .finally(() => this.$bvModal.hide('modal-api-loading'))
      }
    }

    function handleShowModal() {
      hasPaidTrip.value = false

      if (['QH', 'AK', 'TH'].includes(bookingSource.value)) {
        isPayNow.value = true
      }

      if (['PAID'].includes(bookingSource.value.status)) {
        hasPaidTrip.value = true
      }
    }

    function handleHideModal() {
      dataToSelectSeat.value = []
      seatAlreadyChosenArr.value = []
      seatsMapFullCabinsData.value = null
      seatsOption.value = []
      seatsData.value = null
      isEmptySeat.value = false

      // store.dispatch('app-reservation/removeSeatMapData')
      emit('update:isOpenModalSeat', false)
    }

    return {
      bookingData,
      handleConfirmModifySeat,
      totalPricesAddSeat,
      handleShowModal,
      fnAncillary,
      bookingSource,
      getAirlineBySource,
      getAirCraftByIata,
      formatCurrency,
      handleSelectSeat,
      filterSeatBySegment,
      handleDeleteSeatInTable,
      handleHideModal,

      seatsOption,
      isEmpty,
      seatsData,
      dataToSelectSeat,
      seatAlreadyChosenArr,
      getIsEmptySeatMapData,

      isEmptySeat,
      convertISODateTime,

      openSelectPayOptionModalHandle,
      hasPaidTrip,
      isPayNow,

    }
  },
}
</script>
